<template>
  <div class="pay-success">
    <!-- <van-popup
      style="
        font-size: 0.32rem;
        width: calc(100% - 40px);
        max-height: 100px;
        padding: 10px 20px 20px;
        line-height: 1.5;
      "
      round
      position="top"
      v-model="show"
      >亲爱的缘友们，请务必填写手机号码接收结果，如不填写有可能无法接收结果；或点击右侧订单咨询。谢谢配合！</van-popup
    > -->
    <div class="fix-order">
      <img src="https://qiniu.zhiyunys.com/img/order-check.png" @click="$router.push('/order')"/>
    </div>
    <div class="success-outer">
      <div class="success confirm" v-if="isConfirm">
        <h2>订单结果将发送至</h2>
        <p class="phone">{{ phone }}</p>
        <p class="tips">结果仅可发送一次，请仔细核对手机号</p>
        <div class="input-group">
          <a href="javascript:;" @click="confirmPhone">确认无误</a>
          <a
            href="javascript:;"
            @click="cancel"
            style="
              border: 0.5px solid #ccc;
              background: transparent;
              color: #333;
            "
            >有误重填</a
          >
        </div>
        <p class="kefu">
          无法绑定手机号码？
          <span @click="$router.push('/order')">联系客服</span>
        </p>
      </div>
      <div class="success" v-if="!isConfirm">
        <svg
          t="1650184522651"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2629"
          width="80"
          height="80"
        >
          <path
            d="M512 64q190.016 4.992 316.512 131.488T960 512q-4.992 190.016-131.488 316.512T512 960q-190.016-4.992-316.512-131.488T64 512q4.992-190.016 131.488-316.512T512 64z m-56 536l-99.008-99.008q-12-11.008-27.488-11.008t-27.008 11.488-11.488 26.496 11.008 27.008l127.008 127.008q11.008 11.008 27.008 11.008t27.008-11.008l263.008-263.008q15.008-15.008 9.504-36.512t-27.008-27.008-36.512 9.504z"
            p-id="2630"
            fill="#4d9d3a"
          ></path>
        </svg>
        <!-- <p class="title" v-if="!loading && failed">支付失败</p> -->
        <p class="title">支付成功</p><!--v-if="!loading && !failed"-->
        <p class="tips">因平台限制，请输入您的手机号以接收测试结果</p>
        <div class="input-group">
          <input
            v-model="phone"
            type="text"
            maxlength="11"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="请输入手机号码"
          />
          <a href="javascript:;" @click="bindPhone">免费接收测试结果</a>
        </div>
        <p class="argument">
          <span
            @click="isAgree = !isAgree"
            :style="`background: ${isAgree ? '#374e82' : '#fff'}`"
            >✓</span
          >同意<a
            style="cursor: pointer; color: rgb(247, 192, 85)"
            @click="$router.push('/protocol')"
            >《用户协议》</a
          >
          和
          <a
            style="cursor: pointer; color: rgb(247, 192, 85)"
            @click="$router.push('/wrapper')"
            >《隐私协议》</a
          >
        </p>
        <p class="kefu">
          无法绑定手机号码？
          <span @click="$router.push('/order')">联系客服</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/apis/order";

export default {
  data() {
    return {
      status: this.$route.query.status,
      phone: "",
      isAgree: true,
      isConfirm: false,
      failed: true,
      loading: true,
      show: false,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.searchStatus();
    // }, 2000);
    // window.history.pushState(null, null, document.URL);
    // window.addEventListener("popstate", this.onBrowserBack, false);
  },
  // destroyed() {
  //   window.removeEventListener("popstate", this.onBrowserBack, false);
  // },
  methods: {
    // onBrowserBack() {
    //   if (this.$route.path === "/success") {
    //     this.show = true;
    //     window.history.pushState(null, null, document.URL);
    //     return false;
    //   }
    //   return true;
    // },
    async searchStatus() {
      const that = this;
      const odd = localStorage.getItem("odd");
      if (!odd) {
        this.$toast("缺少订单号");
        this.$router.push(`/main`);
        return;
      }
      if (!localStorage.getItem("payway")) {
        this.$toast("无法查询到订单");
        this.$router.push(`/main`);
        return;
      }
      if (localStorage.getItem("payway") === "wx") {
        await api
          .getWxOrder({
            out_trade_no: odd,
          })
          .then((res) => {
            this.loading = false;
            if (res.length === 0) {
              that.failed = true;
              that.$toast("未查询到订单");
              setTimeout(() => {
                that.$router.push(`/order`);
              }, 1000);
            } else if ((res[0] || {}).status === 1) {
              that.failed = false;
            } else {
              that.failed = true;
              that.$toast("支付失败！如已支付，请移步订单咨询查看！");
              // setTimeout(() => {
              //   that.$router.push(`/result${res[0].resultType}`);
              // }, 1000);
            }
          });
      }
      if (localStorage.getItem("payway") === "ali") {
        await api
          .getAliOrder({
            out_trade_no: odd,
          })
          .then((res) => {
            that.loading = false;
            if (res.length === 0) {
              that.failed = true;
              that.$toast("未查询到订单");
              setTimeout(() => {
                that.$router.push(`/order`);
              }, 1000);
            } else if ((res[0] || {}).status === 1) {
              that.failed = false;
            } else {
              that.failed = true;
              that.$toast("支付失败！");
              // setTimeout(() => {
              //   that.$router.push(`/result${res[0].resultType}`);
              // }, 1000);
            }
          });
      }
    },
    confirmPhone() {
      api
        .bindPhone({
          phoneNumber: this.phone,
          odd: localStorage.getItem("odd"),
        })
        .then((res) => {
          if (res.code === 0) {
            api
              .getOrder({
                odd: localStorage.getItem("odd"),
              })
              .then((res) => {
                if (!res.message) {
                  this.$router.push({
                    path:`/resultPayed${res[0].resultType}`,
                    query:{
                      odd:localStorage.getItem("odd")
                    }
                  });
                }
              });
          } else if (!localStorage.getItem("odd")) {
            this.$toast("缺少订单号");
            setTimeout(() => {
              this.$router.push("/main");
            }, 1000);
          } else {
            this.$toast(res.message);
            setTimeout(() => {
              this.$router.push({
                path:`/resultPayed${res[0].resultType}`,
                query:{
                  odd:localStorage.getItem("odd")
                }
              });
            }, 1000);
          }
        });
    },
    cancel() {
      this.isConfirm = false;
    },
    bindPhone() {
      // if (this.failed) {
      //   this.$toast('支付失败！');
      //   return;
      // }
      if (!this.phone) {
        this.$toast("请输入手机号！");
        return;
      }
      if (this.phone[0] !== "1" || this.phone.length < 11) {
        this.$toast("非法手机号！");
        return;
      }
      if (!this.isAgree) {
        this.$toast("请勾选同意用户协议和隐私协议！");
        return;
      }
      this.isConfirm = true;
    },
  },
};
</script>

<style lang="less" scoped>
.pay-success {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  font-size: 0.32rem;
  .success-outer {
    width: 100%;
    box-sizing: border-box;
    padding: 0.54rem 0.4rem;
    .success {
      height: 100%;
      max-width: 460px;
      color: #333;
      font-size: 0.28rem;
      background: url("https://qiniu.zhiyunys.com/img/calendar-bg.png") no-repeat;
      background-size: 100% 100%;
      padding: 0.42rem 0.32rem;
      border-radius: 0 0 16px 16px;
      text-align: center;
      margin: auto;
      .title {
        font-size: 24px;
        margin: 20px 0;
      }
      .tips {
        color: #999;
        font-size: 0.24rem;
      }
      .input-group {
        margin-top: 30px;
        input,
        a {
          height: 0.9rem;
          line-height: 0.9rem;
          // width: 90%;
          display: block;
          border: 1px solid #eee;
          margin-bottom: 20px;
          border-radius: 30px;
          padding: 0 20px;
        }
        input {
          width: calc(100% - 40px);
        }
        a {
          background-color: #374e82;
          color: #fff;
          text-align: center;
        }
      }
      .argument {
        margin-top: 10px;
        margin-bottom: 40px;
        color: #999;
        font-size: 0.24rem;
        span {
          display: inline-block;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          border-radius: 50%;
          background: #374e82;
          color: #fff;
          margin-right: 10px;
          cursor: pointer;
          border: 1px solid #374e82;
        }
      }
      .kefu {
        color: #999;
        font-size: 0.24rem;
        margin-bottom: 20px;
        span {
          text-decoration: underline;
        }
      }
      &.confirm {
        .phone {
          font-size: 26px;
          margin: 10px;
        }
        h2 {
          color: #333;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
          &::before,
          &::after {
            content: "";
            display: inline-block;
            width: 15px;
            height: 1px;
            background: #333;
            margin-right: 8px;
          }
          &::after {
            margin-left: 8px;
          }
        }
        .tips {
          color: #f00;
        }
      }
    }
  }
}
</style>
<template>
  <div class="main">
    <div class="notice">
      <div class="content" :style="{ margin: `0 ${width}px` }">
        我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用切造成一切后果由其监护人自行承担。本网页数据安全由阿里云提供，严格按照法律法规和用户协议对您的信息进行全方位的保护，请放心使用！
      </div>
    </div>
    <div class="tips" v-if="showTips">
      <div class="order-desc">您有进行中的测算订单喔</div>
      <div style="display: flex; align-items: center">
        <div class="go-order" @click="jumpToOrder">立即查看</div>
        <div class="close-tips" @click="showTips = false">关闭</div>
      </div>
    </div>
    <div class="banner">
      <svg
        viewBox="0 0 750 934"
        width="750"
        height="934"
        preserveAspectRatio="xMidYMid meet"
        style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px)"
      >
        <defs>
          <clipPath id="__lottie_element_2">
            <rect width="750" height="934" x="0" y="0"></rect>
          </clipPath>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top0.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top1.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top2.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top3.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top4.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top5.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top6.png"></image>
          <image xlink:href="https://qiniu.zhiyunys.com/img/img_top7.png"></image>
          <clipPath id="__lottie_element_31">
            <path d="M0,0 L750,0 L750,934 L0,934z"></path>
          </clipPath>
        </defs>
        <g clip-path="url(#__lottie_element_2)">
          <g
            class="png"
            transform="matrix(1,0,0,1,58.5,298.5)"
            opacity="1"
            style="display: block"
          >
            <image
              width="633px"
              height="337px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top7.png"
            ></image>
          </g>
          <g
            clip-path="url(#__lottie_element_31)"
            transform="matrix(1,0,0,1,0,0)"
            opacity="1"
            style="display: block"
          >
            <g
              class="png"
              style="display: block"
              transform="matrix(1,0,0,1,133.59628295898438,495.1662902832031)"
              opacity="1"
            >
              <image
                width="27px"
                height="27px"
                preserveAspectRatio="xMidYMid slice"
                xlink:href="https://qiniu.zhiyunys.com/img/img_top6.png"
              ></image>
            </g>
            <g
              class="png"
              transform="matrix(1,0,0,1,449.2303161621094,614.3694458007812)"
              opacity="1"
              style="display: block"
            >
              <image
                width="27px"
                height="27px"
                preserveAspectRatio="xMidYMid slice"
                xlink:href="https://qiniu.zhiyunys.com/img/img_top6.png"
              ></image>
            </g>
          </g>
          <g
            class="png"
            transform="matrix(1,0,0,1,449.22686767578125,614.3690795898438)"
            opacity="1"
            style="display: block"
          >
            <image
              width="27px"
              height="27px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top6.png"
            ></image>
          </g>
          <g
            class="png"
            transform="matrix(1,0,0,1,159.44427490234375,286.6605224609375)"
            opacity="0.18911666665870058"
            style="display: none"
          >
            <image
              width="27px"
              height="27px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top6.png"
            ></image>
          </g>
          <g
            class="png"
            transform="matrix(1,0,0,1,58.5,298.5)"
            opacity="1"
            style="display: block"
          >
            <image
              width="633px"
              height="337px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top5.png"
            ></image>
          </g>
          <g
            class="png"
            transform="matrix(1,0,0,1,672.1927490234375,352.0733337402344)"
            opacity="1"
            style="display: block"
          >
            <image
              width="27px"
              height="27px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top4.png"
            ></image>
          </g>
          <g
            class="png"
            transform="matrix(1,0,0,1,168.95004272460938,387.0038146972656)"
            opacity="0.2118250000059625"
            style="display: block"
          >
            <image
              width="27px"
              height="27px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top4.png"
            ></image>
          </g>
          <g
            id="top3"
            class="png"
            transform="matrix(1,0,0,1,34,158.85580444335938)"
            opacity="1"
            style="display: block"
          >
            <image
              width="70px"
              height="220px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top3.png"
            ></image>
          </g>
          <g
            id="top2"
            class="png"
            transform="matrix(1,0,0,1,632,580.331787109375)"
            opacity="1"
            style="display: block"
          >
            <image
              width="70px"
              height="220px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top2.png"
            ></image>
          </g>
          <g
            id="top1"
            class="png"
            transform="matrix(1,0,0,1,34,551.668212890625)"
            opacity="1"
            style="display: block"
          >
            <image
              width="70px"
              height="220px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top1.png"
            ></image>
          </g>
          <g
            id="top0"
            class="png"
            transform="matrix(1,0,0,1,642,134.97830200195312)"
            opacity="1"
            style="display: block"
          >
            <image
              width="70px"
              height="220px"
              preserveAspectRatio="xMidYMid slice"
              xlink:href="https://qiniu.zhiyunys.com/img/img_top0.png"
            ></image>
          </g>
        </g>
      </svg>
    </div>
    <!-- <div class="purchase-list" style="margin-top: -2px">
      <van-swipe
        style="height: 42px; overflow: hidden"
        :show-indicators="false"
        vertical
        :autoplay="3000"
      >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10019.jpg"
            />
            <div>
              <p class="item-name">来杯冰美式</p>
              <p>6分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10001.jpg"
            />
            <div>
              <p class="item-name">岁月静好</p>
              <p>20分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10015.jpg"
            />
            <div>
              <p class="item-name">小透明</p>
              <p>58秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10004.jpg"
            />
            <div>
              <p class="item-name">Draken</p>
              <p>42秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10020.jpg"
            />
            <div>
              <p class="item-name">薄雾</p>
              <p>20分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10012.jpg"
            />
            <div>
              <p class="item-name">小猪の妈妈</p>
              <p>30分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10002.jpg"
            />
            <div>
              <p class="item-name">红豆洋子苏打</p>
              <p>7分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10014.jpg"
            />
            <div>
              <p class="item-name">芳草</p>
              <p>38秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10005.jpg"
            />
            <div>
              <p class="item-name">西瓜先生</p>
              <p>7分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10010.jpg"
            />
            <div>
              <p class="item-name">顺风顺水</p>
              <p>52秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10013.jpg"
            />
            <div>
              <p class="item-name">魑魅魍魉</p>
              <p>26秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10003.jpg"
            />
            <div>
              <p class="item-name">murli</p>
              <p>34秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10007.jpg"
            />
            <div>
              <p class="item-name">基金大涨-</p>
              <p>40秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10006.jpg"
            />
            <div>
              <p class="item-name">煮熟的鸭子</p>
              <p>26分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10009.jpg"
            />
            <div>
              <p class="item-name">冬雪</p>
              <p>16分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10016.jpg"
            />
            <div>
              <p class="item-name">随遇而安</p>
              <p>18分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10017.jpg"
            />
            <div>
              <p class="item-name">゛旧萤火</p>
              <p>17分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10018.jpg"
            />
            <div>
              <p class="item-name">奥碎冰淇淋</p>
              <p>14秒前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
        <van-swipe-item>
          <li class="buyer">
            <img
              src="https://qiniu.zhiyunys.com/img/10008.jpg"
            />
            <div>
              <p class="item-name">随缘</p>
              <p>25分钟前购买了生辰运势</p>
            </div>
          </li></van-swipe-item
        >
      </van-swipe>
      <div class="line"></div>
      <div class="right">
        <div>
          已有
          <span style="color: rgb(248, 217, 131)">{{ count }}</span> 人
        </div>
        <div>领取生辰测算报告</div>
      </div>
    </div> -->
    <div class="submit" @click="jumpToAnalysis"></div>
    <div class="search-order" @click="jumpToOrder">查询我的订单></div>
    <div class="bg-list">
      <img src="https://qiniu.zhiyunys.com/img/cont1.png" />
      <img src="https://qiniu.zhiyunys.com/img/cont2.png" />
      <img src="https://qiniu.zhiyunys.com/img/cont3.png" />
      <img src="https://qiniu.zhiyunys.com/img/cont4.png" />
    </div>
    <!-- <p class="company">南京天昱翔网络科技有限公司龙湾蒲州分公司</p> -->
    <!-- <p class="company">莆田城厢顺骏网络科技有限公司</p> -->
    <!-- <p class="company">广东智运网络科技有限公司</p> -->
    <!-- <p class="company">广州风吹雨网络科技有限公司</p>
    <p class="company">粤ICP备2022024711号-1</p> -->
    <!-- <p class="company">广州至臻网络科技有限公司</p> -->
    <!-- <p class="company">广州朋客网络科技有限公司</p> -->
    <!-- <p class="company">广州奥陶季文化科技有限公司</p> -->
    <!-- <p class="company">广州智造家网络科技有限公司</p> -->
    <p class="company">温州嘉临电子商务有限公司曲靖麒麟分公司</p>
    <!-- <p class="company">粤ICP备2022024711号-1</p> -->
    

    <!-- yc.szweituo.cn -->
    <!-- <p class="company">深圳市微拓互动科技有限公司</p>
    <p class="company">粤ICP备2022104099号-1</p>
    <p class="company">vivo非服务提供商，服务内容与条款以广告主内容为准</p> -->
    <div class="security">
      <img src="https://qiniu.zhiyunys.com/img/bottom_img.png" />
      <img src="https://qiniu.zhiyunys.com/img/risk-bg.png" />
    </div>
    <!-- <div class="footer">
      <div class="btn" @click="jumpToAnalysis">
        <img src="https://qiniu.zhiyunys.com/img/more.png" />
        <p>更多测算</p>
      </div>
      <div class="btn analysis-submit" @click="jumpToAnalysis"></div>
      <div class="btn" @click="jumpToOrder">
        <img src="https://qiniu.zhiyunys.com/img/order.png" />
        <p>查询订单</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import api from "@/apis/order";

export default {
  data() {
    return {
      width: 0,
      top3Height: 130,
      count: 2204362,
      timer: null,
      showTips: false,
    };
  },
  created() {
    setTimeout(() => {
      this.width = document.getElementById("app").clientWidth;
    });
    this.addPurchase();
    api.getList().then((res) => {
      if (res.find((item) => item.status === 0)) {
        this.showTips = true;
      }
    });
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    addPurchase() {
      this.timer = setInterval(() => {
        this.count += Math.round(Math.random() * 100);
      }, 2000);
    },
    jumpToAnalysis() {
      this.$router.push("/analysis");
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if ((document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop) > (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)) {
        document.getElementsByClassName('submit')[0].style.position = 'fixed';
        document.getElementsByClassName('submit')[0].style.bottom = '0';
        document.getElementsByClassName('submit')[0].style.left = document.getElementsByClassName('bg-list')[0].getBoundingClientRect().left + 5 + 'px';
      } else {
        document.getElementsByClassName('submit')[0].style.position = 'relative';
        document.getElementsByClassName('submit')[0].style.left = '0';
      }
    });
    const showArea = document.querySelector(".notice");
    showArea.scrollLeft = 0;
    function f() {
      if (
        showArea.scrollLeft >=
        showArea.scrollWidth - document.getElementById("app").clientWidth
      ) {
        showArea.scrollLeft = 0;
      } else {
        const speed = 1;
        showArea.scrollLeft += speed;
      }
      requestAnimationFrame(f);
    }
    requestAnimationFrame(f);
  },
};
</script>

<style lang="less">
.main {
  background: #252540;
  position: relative;
  padding-bottom: 220px;
  font-size: 0.23rem;
  // ---------------------------
  // ------------------------------------------
  @keyframes top3 {
    from {
      transform: matrix(1, 0, 0, 1, 34, 130);
    }
    to {
      transform: matrix(1, 0, 0, 1, 34, 160);
    }
  }
  @keyframes top0 {
    from {
      transform: matrix(1, 0, 0, 1, 642, 170);
    }
    to {
      transform: matrix(1, 0, 0, 1, 642, 130);
    }
  }
  @keyframes top1 {
    from {
      transform: matrix(1, 0, 0, 1, 34, 580);
    }
    to {
      transform: matrix(1, 0, 0, 1, 34, 540);
    }
  }
  @keyframes top2 {
    from {
      transform: matrix(1, 0, 0, 1, 642, 550);
    }
    to {
      transform: matrix(1, 0, 0, 1, 642, 590);
    }
  }
  #top3 {
    animation: top3 1.6s linear infinite;
    animation-direction: alternate;
  }
  #top0 {
    animation: top0 1.6s linear infinite;
    animation-direction: alternate;
  }
  #top1 {
    animation: top1 1.6s linear infinite;
    animation-direction: alternate;
  }
  #top2 {
    animation: top2 1.6s linear infinite;
    animation-direction: alternate;
  }
  .notice {
    max-width: 460px;
    width: 100%;
    overflow: hidden;
    background: #333;
    padding: 4px 0;
    height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    .content {
      opacity: 0.7;
      font-size: 14px;
      color: #fff;
      word-break: keep-all;
      white-space: nowrap;
      position: absolute;
      line-height: 1.5;
    }
  }
  .tips {
    display: flex;
    align-items: center;
    height: 0.85rem;
    background-color: #ffd77f;
    padding: 0 0.25rem;
    justify-content: space-between;
    .order-desc {
      display: flex;
      font-size: 0.28rem;
      color: #333;
      align-items: center;
    }
    .go-order {
      width: 1.57rem;
      height: 0.58rem;
      border-radius: 0.32rem;
      text-align: center;
      line-height: 0.58rem;
      font-size: 0.26rem;
      font-weight: 500;
      background-color: #2b2d5d;
      color: #fff;
      margin-right: 0.25rem;
    }
    .close-tips {
      color: #896c35;
      font-size: 0.24rem;
    }
  }
  .banner {
    background: #252540 url("https://qiniu.zhiyunys.com/img/banner1.png") no-repeat;
    background-size: 100% 100%;
  }
  .purchase-list {
    height: 44px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #25253e;
    .buyer {
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      color: #fff;
      line-height: 1.5;
      img {
        margin-right: 0.1rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .line {
      width: 0.01rem;
      height: 0.6rem;
      background: #7a7a7a;
    }
    .right {
      color: #fff;
      margin-left: 0.22rem;
      font-size: 0.24rem;
      line-height: 1.5;
    }
  }
  @keyframes submit {
    from {
      transform: scale(1.8);
    }
    to {
      transform: scale(2);
    }
  }
  .submit {
    width: 7.01rem;
    height: 1.07rem;
    background: url("https://qiniu.zhiyunys.com/img/index_btn.png") no-repeat;
    background-size: 100% 100%;
    margin: 20px auto;
    animation: submit 2s linear infinite;
    animation-direction: alternate;
  }
  .search-order {
    text-align: center;
    font-size: 16px;
    color: #ecd165;
    margin-bottom: 15px;
  }
  .bg-list {
    margin: 0 10px;
    width: calc(100% - 20px);
    img {
      width: 100%;
      margin-bottom: 15px;
    }
    .hot {
      min-height: 100px;
      background: url("https://qiniu.zhiyunys.com/img/index_hot.png") no-repeat;
      background-size: 100%;
      padding-top: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      text-align: center;
      border-radius: 0 0 16px 16px;
      .list {
        width: 25%;
        margin-bottom: 20px;
        img {
          width: 72px;
          height: 72px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .company {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #8585a6;
  }
  .security {
    text-align: center;
    img {
      width: 70%;
      margin-bottom: 20px;
    }
  }
  .footer {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 460px;
    height: 114px;
    background: url("https://qiniu.zhiyunys.com/img/footer-bg.png") no-repeat;
    background-size: 100% 100%;
    .btn {
      position: absolute;
      bottom: 8px;
      text-align: center;
      img {
        width: 38px;
        height: 38px;
      }
      &:first-child {
        left: 0.8rem;
      }
      &:last-child {
        right: 0.8rem;
      }
      p {
        font-size: 14px;
      }
    }
    @keyframes analysis {
      from {
        transform: scale(1.4) translateX(-50%);
      }
      to {
        transform: scale(1.2) translateX(-50%);
      }
    }
    .analysis-submit {
      width: 60px;
      height: 60px;
      background: url("https://qiniu.zhiyunys.com/img/bottom_nav_icon.png")
        no-repeat;
      background-size: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 32px;
      animation: analysis 1.2s linear infinite;
      animation-direction: alternate;
      transform-origin: 0 0;
    }
  }
}
@media screen and (max-width: 414px) {
  .main .bg-list .hot {
    padding-top: 60px;
  }
  .main {
    .footer {
      width: 100%;
      height: 104px;
      .analysis-submit {
        width: 50px;
        height: 50px;
        bottom: 33px;
      }
      .btn {
        &:first-child {
          left: 1rem;
        }
        &:last-child {
          right: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .main {
    .footer {
      width: 100%;
      height: 104px;
      .analysis-submit {
        width: 43px;
        height: 43px;
        bottom: 41px;
      }
      .btn {
        &:first-child {
          left: 0.8rem;
        }
        &:last-child {
          right: 0.8rem;
        }
      }
    }
  }
}
</style>
<template>
  <div class="analysis">
    <div class="fix-order">
      <img
        src="https://qiniu.zhiyunys.com/img/order-check.png"
        @click="$router.push('/order')"
      />
    </div>
    <h1 class="title"></h1>
    <div class="content">
      <p class="tips"></p>
      <div class="form-item">
        <label>姓名：</label>
        <input
          v-model="form.name"
          class="item"
          type="text"
          placeholder="请输入姓名（必须汉字）"
        />
      </div>
      <div class="form-item">
        <label>性别：</label>
        <div class="sex-btn">
          <a :class="`${form.sex === 0 ? 'active' : ''}`" @click="form.sex = 0"
            >男</a
          >
          <a :class="`${form.sex !== 0 ? 'active' : ''}`" @click="form.sex = 1"
            >女</a
          >
        </div>
      </div>
      <div class="form-item">
        <label>生辰：</label>
        <input
          v-model="showDate"
          class="item"
          type="text"
          placeholder="请选择出生日期"
          @click="show = true"
          readonly
        />
      </div>
      <div class="form-footer">
        <div class="submit" @click="findResult"></div>
      </div>
      <div class="argument">
        <img
          @click="isAgree = !isAgree"
          :src="`${
            isAgree
              ? 'https://qiniu.zhiyunys.com/img/agree.png'
              : 'https://qiniu.zhiyunys.com/img/disagree.png'
          }`"
          class="argument_img"
        />
        <span>同意</span>
        <a
          style="cursor: pointer; color: rgb(247, 192, 85)"
          @click="$router.push('/protocol')"
          >《用户协议》</a
        >
        <span>和</span>
        <a
          style="cursor: pointer; color: rgb(247, 192, 85)"
          @click="$router.push('/wrapper')"
          >《隐私协议》</a
        >
      </div>
      <p
        style="
          color: #eee;
          font-size: 10px;
          text-align: center;
          margin-top: 10px;
        "
      >
        测试部分免费，获取报告或解锁全部内容时，需付费查看。
      </p>
    </div>
    <div class="calendar" v-if="show">
      <van-datetime-picker
        class="picker"
        v-model="currentDate"
        type="datehour"
        :columns-order="['year', 'month', 'day']"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
      >
        <slot name="default">
          <div class="switch-btn">
            <a :class="`${isLunar ? '' : 'active'}`" @click="changeFormatter()"
              >公历</a
            >
            <a :class="`${isLunar ? 'active' : ''}`" @click="changeFormatter()"
              >农历</a
            >
          </div>
        </slot>
      </van-datetime-picker>
      <div class="option-btn">
        <a @click="show = false">取消</a>
        <a @click="confirmDate">确定</a>
      </div>
    </div>
    <div class="aliyun">
      <img src="https://qiniu.zhiyunys.com/img/aliyun.png" />
    </div>
  </div>
</template>

<script>
// import Calendar from '../components/calendar.vue';
import dayjs from "dayjs";
import api from "@/apis/order";
import calendar from "@/utils/calendar";

export default {
  data() {
    return {
      minDate: new Date(1940, 1, 1),
      maxDate: new Date(),
      show: false,
      currentDate: new Date(1985, 7, 8),
      showDate: "",
      isLunar: false,
      formatter: (type, val) => {
        if (type === "hour") {
          return val + "时";
        }
        return val;
      },
      form: {
        name: "",
        sex: 0, // 0男 1女
        birthday: "",
      },
      isAgree: true,
      usernBirthTime: "",
      usergBirthTime: "",
      normalDate: "",
      isChild: false,
    };
  },
  components: {
    // Calendar
  },
  mounted() {
    const curDate = new Date();
    const year = curDate.getFullYear();
    const month = curDate.getMonth() + 1;
    const date = curDate.getDate();
    const obj = calendar.solar2lunar(year, month, date);
    console.log(calendar.getAnimal(obj.lYear));
  },
  methods: {
    findResult() {
      // 测算结果
      if (!this.form.name) {
        this.$toast("名字不能为空");
        return;
      }
      if (!this.form.birthday) {
        this.$toast("请选择出生时间");
        return;
      }
      if (!this.isAgree) {
        this.$toast("请阅读并勾选同意用户协议和隐私协议！");
        return;
      }
      if (parseInt(this.isChild) < 18) {
        this.$toast("未满18岁，暂不提供服务！");
        return;
      }
      const { name, sex } = this.form;
      const num = Math.floor(Math.random() * 3) + 1;
      const params = {
        userName: name,
        userSex: sex === 0 ? "男" : "女",
        resultType: num,
        channel: this.$route.query.apkChannel,
        isCallBack: this.$route.query.isCallBack || 0
      };
      params.usernBirthTime = this.usernBirthTime;
      params.usergBirthTime = this.usergBirthTime;
      api.createOrder(params).then((res) => {
        if (res.data) {
          // this.$toast(res.message);
          localStorage.setItem("odd", res.data.odd);
          localStorage.setItem("price", res.data.price);
          localStorage.setItem("payway", "");
          this.$router.push({
            path: `/result${num}`,
            query: {
              name,
              sex: sex === 0 ? "男" : "女",
              birthday: this.normalDate,
            },
          });
          localStorage.setItem("createTime", new Date().getTime());
        }
      });
    },
    getDate(date) {
      let d = new Date(date);
      // 将日期设置为下月一号
      d.setMonth(d.getMonth() + 1);
      d.setDate("1");
      // 获取本月最后一天
      d.setDate(d.getDate() - 1);
      return d.getDate();
    },
    getAge(birthday, lastDay) {
      // 先截取到字符串中的年、月、日
      let selectYear = birthday.split("-")[0];
      let selectMonth = birthday.split("-")[1];
      let selectDay = birthday.split("-")[2];
      // 得到当前时间的年、月、日
      let cal = lastDay === "" ? new Date() : new Date(lastDay);
      let yearNow = cal.getFullYear();
      let monthNow = cal.getMonth() + 1;
      let dayNow = cal.getDate();

      // 用当前年月日减去生日年月日
      let yearMinus = yearNow - selectYear;
      let monthMinus = monthNow - selectMonth;
      let dayMinus = dayNow - selectDay;

      let age = "";
      if (yearMinus < 0) {
        return "生日不可小于当前时间";
      } else {
        if (yearMinus === 0) {
          if (monthMinus < 0) {
            return "生日不可小于当前时间";
          } else {
            if (monthMinus > 0) {
              if (dayMinus >= 0) {
                return (
                  monthMinus + "个月" + (dayMinus > 0 ? dayMinus + "天" : "")
                );
              }
              return (
                (monthMinus - 1 === 0 ? "" : monthMinus - 1 + "个月") +
                (dayNow + (this.getDate(birthday) - selectDay)) +
                "天"
              );
            } else {
              if (dayMinus < 0) {
                return "生日不可小于当前时间";
              }
              return dayMinus + "天";
            }
          }
        } else {
          age = yearMinus + "岁";
          if (monthMinus === 0) {
            if (dayMinus >= 0) {
              return age + (dayMinus === 0 ? "" : dayMinus + "天");
            }
            return (yearMinus - 1 === 0 ? "" : yearMinus - 1 + "岁") + "11个月";
          } else if (monthMinus > 0) {
            age +=
              dayMinus >= 0
                ? monthMinus + "个月"
                : monthMinus - 1 > 0
                ? monthMinus + "个月"
                : this.getDate(birthday) - selectDay + dayNow + "天";
            return age;
          } else {
            return (
              (yearMinus - 1 === 0 ? "" : yearMinus - 1 + "岁") +
              (12 - selectMonth + monthNow) +
              "个月"
            );
          }
        }
      }
    },
    confirmDate() {
      this.form.birthday = this.currentDate.getTime();
      this.show = false;
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth() + 1;
      const date = this.currentDate.getDate();
      const hour = this.currentDate.getHours();
      this.showDate = `${this.isLunar ? "农(阴)历 " : "公(阳)历 "}`;
      const curDate = new Date();
      this.isChild = this.getAge(
        `${year}-${month}-${date}`,
        `${curDate.getFullYear()}-${
          curDate.getMonth() + 1
        }-${curDate.getDate()}`
      );
      if (this.isLunar) {
        const obj = calendar.lunar2solar(
          year,
          month,
          date,
          calendar.leapMonth(year) === month ? true : false
        );
        this.usergBirthTime = `公(阳)历 ${obj.cYear}年${obj.cMonth}月${
          obj.cDay
        }日 ${hour}:00-${hour}:59(${calendar.getGzHour(hour)})时`;
        this.usernBirthTime = `农(阴)历 ${year}年${month}月${date}日 ${hour}:00-${hour}:59(${calendar.getGzHour(
          hour
        )})时`;
        this.showDate = this.usernBirthTime;
        this.normalDate = `农(阴)历 ${year}年${month}月${date}日 ${calendar.getGzHour(
          hour
        )}时`;
      } else {
        const obj = calendar.solar2lunar(year, month, date);
        this.usernBirthTime = `农(阴)历 ${obj.lYear}年${obj.lMonth}月${
          obj.lDay
        }日 ${hour}:00-${hour}:59(${calendar.getGzHour(hour)})时`;
        this.usergBirthTime = `公(阳)历 ${year}年${month}月${date}日 ${hour}:00-${hour}:59(${calendar.getGzHour(
          hour
        )})时`;
        this.showDate = this.usergBirthTime;
        this.normalDate = `公(阳)历 ${year}年${month}月${date}日 ${calendar.getGzHour(
          hour
        )}时`;
      }
    },
    changeFormatter() {
      this.isLunar = !this.isLunar;
      this.formatter = (type, val) => {
        if (type === "hour") {
          return val + "时";
        }
        if (this.isLunar) {
          if (type === "year") {
            return val;
          }
          if (type === "month") {
            const temp = {
              "01": "正",
              "02": "二",
              "03": "三",
              "04": "四",
              "05": "五",
              "06": "六",
              "07": "七",
              "08": "八",
              "09": "九",
              10: "十",
              11: "冬",
              12: "腊",
            };
            return temp[val] + "月";
          }
          if (type === "day") {
            const temp = {
              "01": "初一",
              "02": "初二",
              "03": "初三",
              "04": "初四",
              "05": "初五",
              "06": "初六",
              "07": "初七",
              "08": "初八",
              "09": "初九",
              10: "初十",
              11: "十一",
              12: "十二",
              13: "十三",
              14: "十四",
              15: "十五",
              16: "十六",
              17: "十七",
              18: "十八",
              19: "十九",
              20: "二十",
              21: "甘一",
              22: "甘二",
              23: "甘三",
              24: "甘四",
              25: "甘五",
              26: "甘六",
              27: "甘七",
              28: "甘八",
              29: "甘九",
              30: "三十",
              31: "三一",
            };
            return temp[val];
          }
        }
        return val;
      };
    },
    getMsgFormSon(data) {
      if (Object.keys(data).length !== 0) {
        var disLength = data.lunarm.length;
        var shortName = data.lunarm.substring(disLength - 2, disLength);
        console.log(shortName);
        switch (shortName) {
          case "正月":
            this.m = 1;
            break;
          case "二月":
            this.m = 2;
            break;
          case "三月":
            this.m = 3;
            break;
          case "四月":
            this.m = 4;
            break;
          case "五月":
            this.m = 5;
            break;
          case "六月":
            this.m = 6;
            break;
          case "七月":
            this.m = 7;
            break;
          case "八月":
            this.m = 8;
            break;
          case "九月":
            this.m = 9;
            break;
          case "十月":
            this.m = 10;
            break;
          case "冬月":
            this.m = 11;
            break;
          case "腊月":
            this.m = 12;
            break;
          default:
        }
        this.y = data.lunary;
        this.dindex = data.dindex + 1;
        var time = this.y + "-" + this.m + "-" + this.dindex;
        this.BirthdaySR = dayjs(time).format("YYYY-MM-DD");
        this.userInfo.BirthdaySR = this.BirthdaySR + " " + "23:59:00";
        this.show = false;
        console.log(this.y + "-" + this.m + "-" + this.d);
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="less">
.van-picker__toolbar {
  height: auto;
}
.van-picker__columns {
  padding-bottom: 50px;
}
.van-hairline-unset--top-bottom {
  height: auto !important;
}
.analysis {
  min-height: calc(100vh - 80px);
  background: #1f2f52 url("https://qiniu.zhiyunys.com/img/info_all_bg.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  padding-bottom: 30px;
  font-size: 0.32rem;
  .title {
    width: 295px;
    height: 48px;
    background: url("https://qiniu.zhiyunys.com/img/info-title.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto 30px;
  }
  .aliyun {
    width: 60%;
    margin: 20px auto;
    img {
      width: 100%;
    }
  }
  .content {
    width: calc(100% - 30px);
    min-height: 450px;
    margin: 0 15px;
    background: url("https://qiniu.zhiyunys.com/img/info_bg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 30px;
    .tips {
      width: 244px;
      height: 32px;
      background: url("https://qiniu.zhiyunys.com/img/tips.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto 20px;
    }
    .form-item {
      padding-left: 20px;
      display: flex;
      width: 80%;
      height: 52px;
      background: #525489;
      border-radius: 25px;
      align-items: center;
      margin: 0 auto 20px;
      label {
        color: #fff;
      }
      .sex-btn {
        width: 60%;
        float: left;
        display: flex;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          border: 0.5px solid #7071af;
          font-size: 18px;
          color: #fff;
          border-radius: 50%;
          margin-right: 20px;
          &.active {
            background: #f7c055;
            color: #432200;
          }
        }
      }
      .item {
        background: transparent;
        outline: none;
        border: none;
        color: #fff;
      }
      input::-webkit-input-placeholder {
        color: #a9aac2;
      }
      input::-moz-placeholder {
        color: #a9aac2;
      }
      input:-ms-input-placeholder {
        color: #a9aac2;
      }
      input::-webkit-input-placeholder {
        color: #a9aac2;
      }
      input::placeholder {
        color: #a9aac2;
      }
    }
    .submit {
      width: calc(80% + 20px);
      height: 60px;
      background: url("https://qiniu.zhiyunys.com/img/index_btn.png") no-repeat;
      background-size: 100% 100%;
      margin: 40px auto 30px;
    }
    .argument {
      color: #fff;
      text-align: center;
      .argument_img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  .calendar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    .picker {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: url("https://qiniu.zhiyunys.com/img/calendar-bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .switch-btn {
      margin: 20px auto;
      a {
        display: inline-block;
        width: 140px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 8px 0 0 8px;
        color: #2b2d5d;
        border: 0.01rem solid #2b2d5d;
        &:last-child {
          border-radius: 0 8px 8px 0;
          border-left: none;
        }
        &.active {
          background: #2b2d5d;
          color: #fff;
        }
      }
    }
    .option-btn {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      z-index: 999;
      padding: 20px 20px 100px;
      display: flex;
      justify-content: space-between;
      a {
        display: inline-block;
        width: 165px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        &:first-child {
          background: url("https://qiniu.zhiyunys.com/img/cancel.png") no-repeat;
          background-size: 100% 100%;
          color: #666;
        }
        &:last-child {
          background: url("https://qiniu.zhiyunys.com/img/confirm.png")
            no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }
      }
    }
  }
}
</style>
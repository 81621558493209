import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
Vue.config.productionTip = false

import less from 'less'
Vue.use(less)

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import Main from '@/pages/Main'
import Analysis from '@/pages/Analysis'
import Order from '@/pages/Order'
import Guide from '@/pages/Guide'
import Help from '@/pages/Help'
import Result1 from '@/pages/Result1'
import Result2 from '@/pages/Result2'
import Result3 from '@/pages/Result3'
import WxPay from '@/pages/WxPay'
import Success from '@/pages/Success'
import ResultPayed from '@/pages/ResultPayed'
import ResultPayed1 from '@/pages/ResultPayed1'
import ResultPayed2 from '@/pages/ResultPayed2'
import ResultPayed3 from '@/pages/ResultPayed3'
import Wrapper from '@/pages/Wrapper'
import Protocol from '@/pages/Protocol'
import PayResult from '@/pages/PayResult'

import fastClick from 'fastclick';
fastClick.attach(document.body);
fastClick.prototype.needsFocus = function(target) { //判断当前元素是否需要focus
  switch (target.nodeName.toLowerCase()) {
      case 'textarea':
          return true;
      case 'select':
          return !(navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1);
      case 'input':
          switch (target.type) {
              case 'button':
              case 'checkbox':
              case 'file':
              case 'image':
              case 'radio':
              case 'submit':
                  return false;
          }
          // No point in attempting to focus disabled inputs
          return !target.disabled && !target.readOnly;
      default:
          return (/\bneedsfocus\b/).test(target.className);
  }
};
fastClick.prototype.focus = function(targetElement) {
  var length;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
      length = targetElement.value.length;
      targetElement.setSelectionRange(length, length);
      /*修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘*/
      targetElement.focus();
  } else {
      targetElement.focus();
  }
};

function setRootFontSize() {
  var width = document.documentElement.clientWidth || document.body.clientWidth;
  if (width > 460) {
      width = 460;
  }
  const fontSize = (width * 0.1333);
  document.getElementsByTagName('html')[0].style['font-size'] = fontSize + 'px';
}
setRootFontSize();
window.addEventListener('resize', function() {
  setRootFontSize();
}, false);
const router = new VueRouter({
  mode: 'history',
  base: '/bdaziweidoushu/',
  routes: [
    { path: '/', redirect: '/main'},
    { path: '/main', component: Main },
    { path: '/analysis', component: Analysis },
    { path: '/order', component: Order },
    { path: '/guide', component: Guide },
    { path: '/help', component: Help },
    { path: '/result1', component: Result1 },
    { path: '/result2', component: Result2 },
    { path: '/result3', component: Result3 },
    { path: '/wxPay', component: WxPay },
    { path: '/success', component: Success },
    { path: '/resultByOdd', component: ResultPayed },
    { path: '/resultPayed1', component: ResultPayed1 },
    { path: '/resultPayed2', component: ResultPayed2 },
    { path: '/resultPayed3', component: ResultPayed3 },
    { path: '/wrapper', component: Wrapper },
    { path: '/protocol', component: Protocol },
    { path: '/payResult', component: PayResult },
  ]
})
router.beforeEach((to, from, next) => {
  console.log('to.query.apkChannel',to.query.apkChannel);
  let query = { ...to.query };
  if (!to.query.apkChannel) {
    // query.apkChannel = 'BD_zwds01_ty_A04_lh';
    query.apkChannel = from.query.apkChannel || 'noChannel';
  }
  if (!to.query.isCallBack) {
    query.isCallBack = from.query.isCallBack || 0;
  }
  if (!to.query.bd_vid) {
    query.bd_vid = from.query.bd_vid;
  }
  if (!to.query.pageId) { // oppo
    query.pageId = from.query.pageId;
  }
  if (!to.query.tid) { // oppo
    query.tid = from.query.tid;
  }
  if (!to.query.lbid) { // oppo
    query.lbid = from.query.lbid;
  }
  if (!to.query.adid) { // vivo
    query.adid = from.query.adid;
  }
  if (!to.query.requestid) { // vivo
    query.requestid = from.query.requestid;
  }
  if (!to.query.mark_id) { // sina
    query.mark_id = from.query.mark_id;
  }
  if (to.query.apkChannel) {
    next();
  } else {
    next({
      path: to.path === '/' ? '' : to.path,
      query,
    });
  }
})
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

<template>
  <div class="analysis-result">
    <div class="fix-order">
      <img src="https://qiniu.zhiyunys.com/img/order-check.png" @click="$router.push('/order')"/>
    </div>
    <img src="https://qiniu.zhiyunys.com/img/natal_title.png" class="title" />
    <div class="container">
      <img class="icon biandong1" src="https://qiniu.zhiyunys.com/img/badge_biandong.png" />
      <img class="icon jiyu1" src="https://qiniu.zhiyunys.com/img/badge_jiyu.png" />
      <img class="icon tupo1" src="https://qiniu.zhiyunys.com/img/badge_tupo.png" />
      <div class="row row1">
        <div class="box box6">
          <div class="header">
            <div class="ziweixing">
              太阳
              <span>禄</span>
            </div>
            <div class="label"></div>
          </div>
          <div class="footer">
            <div class="gongwei">兄弟宫</div>
            <div class="ganzhi">辛巳</div>
          </div>
        </div>
        <div class="box box7">
          <div class="header">
            <div class="ziweixing">破军</div>
            <div class="label"></div>
          </div>
          <div class="footer">
            <div class="gongwei">命宫</div>
            <div class="ganzhi">壬午</div>
          </div>
        </div>
        <div class="box box8">
          <div class="header">
            <div class="ziweixing">天机<span></span></div>
            <div class="label"></div>
          </div>
          <div class="footer">
            <div class="gongwei">疾厄宫</div>
            <div class="ganzhi">癸未</div>
          </div>
        </div>
        <div class="box box9">
          <div class="header">
            <div class="ziweixing">紫微<span></span></div>
            <div class="label">天府</div>
          </div>
          <div class="footer">
            <div class="gongwei">福德宫</div>
            <div class="ganzhi">甲申</div>
          </div>
        </div>
      </div>
      <div class="row row2">
        <div class="box-container">
          <div class="box box5">
            <div class="header">
              <div class="ziweixing">武曲<span>权</span></div>
              <div class="label"></div>
            </div>
            <div class="footer">
              <div class="gongwei">夫妻宫</div>
              <div class="ganzhi">庚辰</div>
            </div>
          </div>
          <div class="box box4">
            <div class="header">
              <div class="ziweixing">天同<span>忌</span></div>
              <div class="label"></div>
            </div>
            <div class="footer">
              <div class="gongwei">子女宫</div>
              <div class="ganzhi">己卯</div>
            </div>
          </div>
        </div>
        <div class="middle box box13">
          <div class="content">
            <p class="middle1"><label>姓名：</label>{{ info.userName }}</p>
            <p class="middle2"><label>性别：</label>{{ info.userSex }}</p>
            <div class="middle3">
              <label>出生时间：</label>
              <p class="middle4">{{ info.usergBirthTime }}</p>
            </div>
            <p class="middle5 red"><label>命局：</label>木三局</p>
            <p class="middle6 red"><label>命主：</label>破军</p>
            <p class="middle7 red"><label>身主：</label>火星</p>
          </div>
        </div>
        <div class="box-container">
          <div class="box box10">
            <div class="header">
              <div class="ziweixing"><span>科</span></div>
              <div class="label">太阴</div>
            </div>
            <div class="footer">
              <div class="gongwei">田宅宫</div>
              <div class="ganzhi">乙酉</div>
            </div>
          </div>
          <div class="box box11">
            <div class="header">
              <div class="ziweixing">贪狼</div>
              <div class="label"></div>
            </div>
            <div class="footer">
              <div class="gongwei">事业宫</div>
              <div class="ganzhi">丙戌</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row3">
        <div class="box box3">
          <div class="header">
            <div class="ziweixing">七杀</div>
            <div class="label"></div>
          </div>
          <div class="footer">
            <div class="gongwei">财帛宫</div>
            <div class="ganzhi">戊寅</div>
          </div>
        </div>
        <div class="box box2">
          <div class="header">
            <div class="ziweixing">天梁</div>
            <div class="label"></div>
          </div>
          <div class="footer">
            <div class="gongwei">父母宫</div>
            <div class="ganzhi">己丑</div>
          </div>
        </div>
        <div class="box box1">
          <div class="header">
            <div class="ziweixing">廉贞</div>
            <div class="label">天相</div>
          </div>
          <div class="footer">
            <div class="gongwei">迁移宫</div>
            <div class="ganzhi">戊子</div>
          </div>
        </div>
        <div class="box box12">
          <div class="header">
            <div class="ziweixing">巨门</div>
            <div class="label"></div>
          </div>
          <div class="footer">
            <div class="gongwei">交友宫</div>
            <div class="ganzhi">丁亥</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      style="
        text-align: center;
        color: #fff;
        font-size: 0.3rem;
        margin: 20px;
        padding: 20px;
        border-radius: 10px;
        background: #3a3d6c;
      "
    >
      <span v-if="show1">1.正在输入命主信息</span>
      <span v-if="show2">2.正在安命宫</span>
      <span v-if="show3">3.正在计算生辰与天干</span>
      <span v-if="show4">4.正在生成报告</span>
      <van-progress
        style="margin-top: 15px"
        :percentage="percentage"
        stroke-width="8"
        color="#fae296"
        :show-pivot="false"
      />
    </div>
    <div class="tips" v-if="!loading">
      根据计算显示，你{{year}}年的<span> 感情、财富、事业 </span>会出现重要情况
    </div>
    <div v-if="!loading" class="submit" @click="showPay = true"></div>
    <div class="pay-container" v-if="showPay">
      <div class="pay-outer">
        <div class="close" @click="handle_close">
          <span></span>
        </div>
        <div class="pay">
          <h2>{{info.userName.substr(0, 1)}}**的生辰综合祥批</h2>
          <div class="content-wrap">
            <p>
              {{year}}年凶星:
              <span>天厄、破碎</span>
            </p>
            <p>
              欠缺信息:
              <span>原局缺金</span>
            </p>
            <p>
              先天格局:
              <span>一生或有大权</span>
            </p>
            <p>当前是:</p>
            <p>
              <span>壬午运(吉2018～2027)</span>
            </p>
          </div>
          <p class="price">
            <img
              src="https://qiniu.zhiyunys.com/img/sale.png"
              alt="结缘价"
              style="width: 1.17rem; height: 0.51rem; margin-right: 0.21rem"
            />{{ totalPrice }} <span class="org">原价¥188</span>
          </p>
          <div class="countTime">
            距优惠结束还剩
            <div class="time-out">
              <p>{{ hour }}</p>
              :
              <p>{{ minute }}</p>
              :
              <p>{{ second }}</p>
            </div>
          </div>
          <div class="payway_btn">
            <!-- <img src="https://qiniu.zhiyunys.com/img/icon_hot.png" class="icon_hot" /> -->
            <div class="wxpay btn" @click="wxpay">
              <img src="https://qiniu.zhiyunys.com/img/weixin.png" />
              微信支付
            </div>
            <div class="alipay btn" @click="alipay">
              <img src="https://qiniu.zhiyunys.com/img/zhifubao.png" />
              支付宝支付
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 返回事件 红包弹窗 -->
    <div class="result" v-if="!loading">
      <div class="pay-return" v-if="showCountDown" @click.stop="showCountDown = false">
          <div class="pay-return-content">
              <h4>恭喜获得红包！</h4>
              <div class="pay-return-price">
                  <p><span>20.00</span>元</p>
                  <p>订单金额 &gt; 20.00元可用</p>
                  <p>可在购买测算时进行抵扣</p>
              </div>
              <div class="pay-return-time">
                  <div class="timer" style="margin-right:5px">
                      <span>{{ formatTime(tenMinutes.minutes) }}</span>:
                      <span>{{ formatTime(tenMinutes.seconds) }}</span>:
                      <span>{{ formatMilliseconds(tenMinutes.milliseconds) }}</span>
                  </div>后失效
              </div>
              <button class="pay-return-btn" @click="getRedPacket()">立即领取</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/apis/order";

export default {
  data() {
    return {
      price: localStorage.getItem('price'),
      year: (new Date()).getFullYear(),
      showPay: false,
      hour: "00",
      minute: "00",
      second: "00",
      loading: true,
      percentage: 0,
      show1: true,
      show2: false,
      show3: false,
      show4: false,
      info: {},
      redPacket: 0,
      showCountDown: false,
      tenMinutes: {
        minutes: 10,
        seconds: 0,
        milliseconds: 0
      },
    };
  },
  computed: {
    totalPrice() {
        const result = (this.info.price - this.redPacket).toFixed(2); // 保留两位小数
        return Number(result).toString();
    }
  },
  methods: {
    handle_close(){
      this.showCountDown = true;
      this.startTimer();
      // this.showPay = false
    },
    handleBackButton(event) {
      // 右滑返回事件触发时的处理逻辑
      event.preventDefault() // 阻止默认的返回行为
      this.showCountDown = true;
      this.startTimer();
    },
    startTimer() {
      let totalMilliseconds = this.tenMinutes.minutes * 60000 + this.tenMinutes.seconds * 1000;
      const timer = setInterval(() => {
          if (totalMilliseconds > 0) {
              totalMilliseconds -= 10;
              this.tenMinutes.minutes = Math.floor(totalMilliseconds / 60000);
              this.tenMinutes.seconds = Math.floor((totalMilliseconds % 60000) / 1000);
              this.tenMinutes.milliseconds = totalMilliseconds % 1000;
          } else {
              clearInterval(timer);
          }
      }, 10);
    },
    formatTime(time) {
      return String(time).padStart(2, '0');
    },
    formatMilliseconds(milliseconds) {
      return String(Math.floor(milliseconds / 10)).padStart(2, '0');
    },
    getRedPacket() {
      localStorage.setItem('redPacket' + this.$route.query.odd, 20);
      this.redPacket = 20;
      this.wxpay();
    },
    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        return true; // 移动端
      } else {
        return false; // PC端
      }
    },
    alipay() {
      localStorage.setItem('payway', 'ali');
      api
        .alipay1({
          type: this.isMobile() ? "mobile" : "pc",
          odd: localStorage.getItem("odd"),
          url: location.href,
          Channel: this.$route.query.apkChannel,
          domain: location.href.replace(this.$route.path, '/payResult'),
          redPacket: this.redPacket,
        })
        .then((res) => {
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res;
          document.body.appendChild(div);
          document.getElementById("alipaysubmit").submit();
        });
      // api
      //   .alipay1({
      //     type: this.isMobile() ? "mobile" : "pc",
      //     odd: localStorage.getItem("odd"),
      //     url: location.href,
      //     Channel: this.$route.query.apkChannel,
      //     domain: location.href.replace(this.$route.path, '/payResult'),
      //   })
      //   .then((res) => {
      //     let divForm = document.getElementsByTagName("divform");
      //     if (divForm.length) {
      //       document.body.removeChild(divForm[0]);
      //     }
      //     const div = document.createElement("divform");
      //     div.innerHTML = res;
      //     document.body.appendChild(div);
      //     document.getElementById("alipaysubmit").submit();
      //   });
    },
    wxpay() {
      localStorage.setItem('payway', 'wx');
      if (this.isMobile()) {
        api
          .wxpay1({
            type: "mobile",
            odd: localStorage.getItem("odd"),
            url: location.href,
            Channel: this.$route.query.apkChannel,
            domain: location.href.replace(this.$route.path, '/payResult'),
            redPacket: this.redPacket,
          })
          .then((res) => {
            location.href = res.code_url;
          });
      } else {
        api
          .wxpay1({
            type: "pc",
            odd: localStorage.getItem("odd"),
            url: location.href,
            Channel: this.$route.query.apkChannel,
            domain: location.href.replace(this.$route.path, '/payResult'),
            redPacket: this.redPacket,
          })
          .then((res) => {
            this.$router.push({
              path: "/wxPay",
              query: {
                info: JSON.stringify(res),
              },
            });
          });
      }
      // if (this.isMobile()) {
      //   api
      //     .wxpay1({
      //       type: "mobile",
      //       odd: localStorage.getItem("odd"),
      //       url: location.href,
      //       Channel: this.$route.query.apkChannel,
      //       domain: location.href.replace(this.$route.path, '/payResult'),
      //     })
      //     .then((res) => {
      //       location.href = res.code_url;
      //     });
      // } else {
      //   api
      //     .wxpay1({
      //       type: "pc",
      //       odd: localStorage.getItem("odd"),
      //       url: location.href,
      //       Channel: this.$route.query.apkChannel,
      //       domain: location.href.replace(this.$route.path, '/payResult'),
      //     })
      //     .then((res) => {
      //       this.$router.push({
      //         path: "/wxPay",
      //         query: {
      //           info: JSON.stringify(res),
      //         },
      //       });
      //     });
      // }
    },
    countDown() {
      // 预计时间
      const _this = this;
      var expectTime =
        +(localStorage.getItem("createTime") || new Date().getTime()) +
        8 * 60 * 60 * 1000;
      var nowTime = new Date().getTime(); //当前时间总的毫秒数
      var inputTime = expectTime; //用户输入时间总的毫秒数
      var times = (inputTime - nowTime) / 1000; //times是剩余时间总的毫秒数
      var timer = setInterval(function () {
        times--;
        // var d = (d = parseInt(times / 60 / 60 / 24))
        // d = d < 10 ? "0" + d : d
        var h = parseInt((times / 60 / 60) % 24);
        h = h < 10 ? "0" + h : h;
        var m = parseInt((times / 60) % 60);
        m = m < 10 ? "0" + m : m;
        var s = parseInt(times % 60);

        if (s > 0) {
          s = s < 10 ? "0" + s : s;
          _this.hour = h;
          _this.minute = m;
          _this.second = s;
        }
        if (s == 0) {
          clearInterval(timer);
        }
        if (s < 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
  },
  created() {
    api
      .getOrder({
        odd: localStorage.getItem("odd"),
      })
      .then((res) => {
        if (!res.message) {
          this.info = res[0];
        }
      });
  },
  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
    window.addEventListener('popstate', this.handleBackButton);
    history.pushState(null, null, document.URL);
    let timer = null;
    let count = 1;
    let num = 4;
    timer = setInterval(() => {
      if (this.percentage === 100) {
        this[`show${++count}`] = true;
        for (let i = 1; i <= num; i++) {
          if (count !== i) {
            this[`show${i}`] = false;
          }
        }
        this.percentage = 0;
        if (count > 4) {
          clearInterval(timer);
          timer = null;
          this.loading = false;
          window.scrollTo(0, 100);
        }
      }
      this.percentage++;
    }, 50);
    this.countDown();
    document
      .getElementsByClassName("middle7")[0]
      .addEventListener("webkitAnimationEnd", function () {
        const boxs = document.getElementsByClassName("box");
        let descs = [];
        Array.from(boxs)
          .sort((a, b) => {
            let indexA = a.className.indexOf("box box");
            let indexB = b.className.indexOf("box box");
            return (
              +a.className.substring(indexA + 7) -
              +b.className.substring(indexB + 7)
            );
          })
          .forEach((item, index) => {
            if (index === 0) {
              item.style.opacity = 1;
              item.style.animation = `bigger 0.4s linear ${0.8 * index}s`;
              item.style.animationFillMode = "backwards";
            }
            if (index !== boxs.length - 1) {
              descs.push(item.querySelector(".gongwei"));
            }
          });
        Array.from(boxs)
          .sort((a, b) => {
            let indexA = a.className.indexOf("box box");
            let indexB = b.className.indexOf("box box");
            return (
              +a.className.substring(indexA + 7) -
              +b.className.substring(indexB + 7)
            );
          })
          .forEach((item, index) => {
            if (index !== boxs.length - 1) {
              const header = item.querySelector(".header");
              if (header.text !== "") {
                descs.push(header);
              }
            }
          });
        descs.forEach((item, index) => {
          item.style.animation = `show 0.2s linear ${0.2 * (index + 1) + 0.8}s`;
          item.style.animationFillMode = "forwards";
          if (index === descs.length - 1) {
            item.addEventListener("webkitAnimationEnd", function () {
              document.querySelector(
                ".jiyu1"
              ).style.animation = `jiyu1 1.6s linear 0.2s`;
              document.querySelector(".jiyu1").style.animationFillMode =
                "forwards";
              document.querySelector(
                ".tupo1"
              ).style.animation = `tupo1 1.6s linear 1.8s`;
              document.querySelector(".tupo1").style.animationFillMode =
                "forwards";
              document.querySelector(
                ".biandong1"
              ).style.animation = `biandong1 1.6s linear 3.4s`;
              document.querySelector(".biandong1").style.animationFillMode =
                "forwards";
            });
          }
        });
      });
  },
  destroyed() {
    window.removeEventListener('hashchange', this.handleBackButton);
    window.removeEventListener('popstate', this.handleBackButton);
  },
};
</script>

<style lang="less">
.analysis-result {
  min-height: 100vh;
  background: #1f2f52 url("https://qiniu.zhiyunys.com/img/pay_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 25px 0;
  position: relative;
  overflow: hidden;
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes bigger {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
  @keyframes jiyu1 {
    from {
      // opacity: 0;
      z-index: 999;
      transform: translate(0, 0) scale(1);
    }
    to {
      opacity: 1;
      z-index: 999;
      transform: translate(-2.05745rem, 1.85431rem) scale(0.1);
    }
  }
  @keyframes tupo1 {
    from {
      // opacity: 0;
      z-index: 999;
      transform: translate(0, 0) scale(1);
    }
    to {
      opacity: 1;
      z-index: 999;
      transform: translate(3.13657rem, -0.07864rem) scale(0.1);
    }
  }
  @keyframes biandong1 {
    from {
      // opacity: 0;
      z-index: 999;
      transform: translate(0, 0) scale(1);
    }
    to {
      opacity: 1;
      z-index: 999;
      transform: translate(-2.05745rem, -1.95088rem) scale(0.1);
    }
  }
  .title {
    width: 282px;
    display: block;
    margin: auto;
  }
  .container {
    position: relative;
    width: 100%;
    // height: 7.48rem;
    padding: 0 0.34rem;
    box-sizing: border-box;
    margin-top: 0.4rem;
    .icon {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      &.tupo1,
      &.jiyu1,
      &.biandong1 {
        opacity: 0;
        z-index: -1;
      }
    }
    .row {
      display: flex;
      .box {
        position: relative;
        height: 1.76rem;
        background: url("https://qiniu.zhiyunys.com/img/gongge.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        font-size: 0.26rem;
        opacity: 0;
        &.box1 {
          animation: show 0.4s linear;
          animation-fill-mode: forwards;
        }
        &.box2 {
          animation: show 0.4s linear 0.4s;
          animation-fill-mode: forwards;
        }
        &.box3 {
          animation: show 0.4s linear 0.8s;
          animation-fill-mode: forwards;
        }
        &.box4 {
          animation: show 0.4s linear 1.2s;
          animation-fill-mode: forwards;
        }
        &.box5 {
          animation: show 0.4s linear 1.6s;
          animation-fill-mode: forwards;
        }
        &.box6 {
          animation: show 0.4s linear 2s;
          animation-fill-mode: forwards;
        }
        &.box7 {
          animation: show 0.4s linear 2.4s;
          animation-fill-mode: forwards;
        }
        &.box8 {
          animation: show 0.4s linear 2.8s;
          animation-fill-mode: forwards;
        }
        &.box9 {
          animation: show 0.4s linear 3.2s;
          animation-fill-mode: forwards;
        }
        &.box10 {
          animation: show 0.4s linear 3.6s;
          animation-fill-mode: forwards;
        }
        &.box11 {
          animation: show 0.4s linear 4s;
          animation-fill-mode: forwards;
        }
        &.box12 {
          animation: show 0.4s linear 4.4s;
          animation-fill-mode: forwards;
        }
        &.box13 {
          animation: show 0.4s linear 4.8s;
          animation-fill-mode: forwards;
        }
        .middle1 {
          animation: show 0.4s linear 5.2s;
          animation-fill-mode: forwards;
        }
        .middle2 {
          animation: show 0.4s linear 5.6s;
          animation-fill-mode: forwards;
        }
        .middle3 {
          animation: show 0.4s linear 6s;
          animation-fill-mode: forwards;
        }
        .middle4 {
          animation: show 0.4s linear 6.4s;
          animation-fill-mode: forwards;
        }
        .middle5 {
          animation: show 0.4s linear 6.8s;
          animation-fill-mode: forwards;
        }
        .middle6 {
          animation: show 0.4s linear 7.2s;
          animation-fill-mode: forwards;
        }
        .middle7 {
          animation: show 0.4s linear 7.6s;
          animation-fill-mode: forwards;
        }
        .header {
          padding: 0.13rem 0.13rem 0;
          display: flex;
          align-items: flex-start;
          opacity: 0;
          .label,
          .ziweixing {
            width: 16px;
            line-height: 1.2;
            margin-right: 6px;
            color: #fff;
            span {
              color: #ff5c5f;
            }
          }
        }
        .footer {
          .gongwei {
            width: 47px;
            height: 28px;
            background: url("https://qiniu.zhiyunys.com/img/desc_bg.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            line-height: 1.7;
            overflow: hidden;
            text-align: center;
            opacity: 0;
          }
          .ganzhi {
            width: 16px;
            line-height: 1.5;
            color: #f8d983;
            position: absolute;
            bottom: 8px;
            right: 6px;
          }
        }
      }
      &.row1,
      &.row3 {
        .box {
          position: relative;
          width: 25%;
          height: 106px;
          margin: 0 0.065rem 0.13rem;
          background: url("https://qiniu.zhiyunys.com/img/gongge.png") no-repeat;
          background-size: 100% 100%;
          .gongwei {
          }
          .ganzhi {
            color: #f8d983;
          }
        }
      }
      &.row2 {
        .box-container {
          width: 25%;
          height: 3.66rem;
          margin: 0 0.065rem;
          .box {
            width: 100%;
          }
        }
        .box.middle {
          width: 50%;
          height: 3.66rem;
          margin: 0 0.065rem 0.13rem;
          background: url("https://qiniu.zhiyunys.com/img/middle_gongge.png") no-repeat;
          background-size: 100% 100%;
          .content {
            padding: 10px 18px;
            p,
            div {
              line-height: 1.4;
              color: #f8da87;
              opacity: 0;
              &.red {
                color: rgb(255, 92, 95);
              }
              label {
                color: #8d8d9c;
              }
            }
            div {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  .tips {
    font-size: 0.3rem;
    color: #fff;
    text-align: center;
    line-height: 1.5;
    width: 6.62rem;
    margin: 0.6rem auto 0;
    span {
      color: #f7c055;
    }
  }
  @keyframes submit {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.6);
    }
  }
  .submit {
    width: 7.01rem;
    height: 1.07rem;
    font-size: 0.51rem;
    font-weight: 900;
    color: #412205;
    background: url("https://qiniu.zhiyunys.com/img/pay-btn.png") no-repeat;
    background-size: 100% 100%;
    margin: 40px auto;
    animation: submit 1.6s linear infinite;
    animation-direction: alternate;
  }
  .pay-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    .pay-outer {
      width: 7.64rem;
      height: 10.17rem;
      box-sizing: border-box;
      padding: 0.54rem 0.4rem;
      margin: auto;
      .close {
        text-align: right;
        span {
          width: 0.84rem;
          height: 0.84rem;
          display: inline-block;
          background: url("https://qiniu.zhiyunys.com/img/close.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .pay {
        height: 100%;
        max-width: 460px;
        color: #333;
        font-size: 0.28rem;
        background: url("https://qiniu.zhiyunys.com/img/payway_bg.jpg") no-repeat;
        background-size: 100% 100%;
        padding: 0.42rem 0.32rem;
        h2 {
          font-size: 0.38rem;
          font-weight: 700;
          line-height: 0.54rem;
          margin-bottom: 0.31rem;
        }
        .content-wrap {
          font-size: 0.31rem;
          color: #333;
          margin-bottom: 0.72rem;
          p {
            margin-bottom: 0.1rem;
            line-height: 1.5;
            span {
              color: #cf1f1f;
            }
          }
        }
        .price {
          margin-top: 0.3rem;
          font-size: 0.5rem;
          font-family: Bebas;
          color: #cf1f1f;
          line-height: 0.48rem;
          display: flex;
          align-items: flex-end;
          .org {
            margin-left: 10px;
            font-size: 0.24rem;
            color: #999;
            line-height: 0.36rem;
            text-decoration: line-through;
          }
        }
        .countTime {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.24rem;
          color: #333;
          line-height: 0.36rem;
          .time-out {
            display: flex;
            margin-left: 0.24rem;
            text-align: center;
            color: #e14958;
            p {
              width: 0.32rem;
              margin-right: 2px;
              padding: 4px;
              font-weight: 700;
              text-align: center;
              color: #fff;
              background: #cf1f1f;
              border-radius: 2px;
            }
          }
        }
        .payway_btn {
          position: relative;
          margin-top: 0.3rem;
          .icon_hot {
            position: absolute;
            right: 0.1rem;
            top: -0.3rem;
            width: 0.86rem;
            height: 0.46rem;
          }
          .alipay {
            margin-top: 0.25rem;
            background: #2978d8;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.16rem;
            height: 1rem;
            font-size: 0.38rem;
            font-weight: 700;
            color: #fff;
            line-height: 0.54rem;
            border-radius: 0.5rem;
            img {
              margin-right: 5px;
              width: 0.58rem;
              height: 0.58rem;
            }
          }
          .wxpay {
            background: #33ba5a;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.16rem;
            height: 1rem;
            font-size: 0.38rem;
            font-weight: 700;
            color: #fff;
            line-height: 0.54rem;
            border-radius: 0.5rem;
            img {
              margin-right: 5px;
              width: 0.58rem;
              height: 0.58rem;
            }
          }
        }
      }
    }
  }
}
.pay-return {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .65);
    z-index: 10000;
}


.pay-return-content {
    position: fixed;
    width: 280px;
    height: 310px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url('https://qiniu.zhiyunys.com/hmcs/static/redPacket.png') 0 0 no-repeat;
    background-size: 100% 100%;
    padding: 20px 0;
    text-align: center
}

.pay-return-content h4 {
    margin-top: 20px;
    font-size: 24px;
    color: #db8014
}

.pay-return-price p:first-child {
    padding-top: 10px;
    font-size: 32px;
    color: #df062a
}

.pay-return-price p:first-child span {
    font-size: 42px;
}

.pay-return-price p:nth-child(2) {
    width: 160px;
    background: rgba(255, 141, 48, .24);
    border: 1px solid rgba(188, 78, 14, .24);
    margin: 0 auto;
    color: #bd5815;
    font-size: 14px;
    margin-top: 5px;
    padding: 5px 0;
}

.pay-return-price p:nth-child(3) {
    color: #bc4e0e;
    font-size: 14px;
    opacity: .5;
    margin-top: 5px;
}

.pay-return-time {
    margin-top: 65px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px
}

.pay-return-time span {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border: 1px solid #ff4e50;
    background: #ca1929;
    text-align: center;
    line-height: 30px;
}

.pay-return-btn {
    margin-top: 20px;
    width: 150px;
    height: 40px;
    border-radius: 50px;
    -webkit-box-shadow: 0 .4688rem .5625rem 0 rgba(138, 0, 12, .26), inset 0 .2813rem .5625rem 0 #fff;
    box-shadow: 0 .4688rem .5625rem 0 rgba(138, 0, 12, .26), inset 0 .2813rem .5625rem 0 #fff;
    background-color: #fb9d3f;
    border: none;
    font-size: 24px;
    color: #6c3000;
    font-weight: 700
}
</style>